import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { darken } from "polished";
import {
  IoOpenOutline,
  IoChatbubbles,
  IoHelpBuoy,
  IoLogoYoutube,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io5";

import { useModal } from "../components/useModal";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Button, Container, Wrapper, Row, Box } from "../components/util";
import { WebsiteContactForm } from "../components/forms";
import { Modal, CallToAction } from "../components/site";

const SupportCard = styled(Box)`
  padding: 60px;
  text-align: center;
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 30px;
  }
`;

const Contact = (props) => {
  const [itemModalOpen, setItemModalOpen] = useModal();

  const handleModal = function() {
    setItemModalOpen(true);
  };

  useEffect(
    function() {
      if (props.location.search.substr(1) === "sales") {
        setItemModalOpen(true);
      }
    },
    [props.location.search, setItemModalOpen]
  );

  return (
    <Layout>
      <Seo
        title="How Can We Help? | Contact Us | Droppah"
        description="Have any questions? Here's how to get in touch with our friendly support & sales team."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper className="-textCenter">
          <Box stackGap={60}>
            <h1>Hello. How can we help?</h1>
            <Row stackGap={40}>
              <SupportCard className="primary" stackGap={40} size={50}>
                <Box stackGap={10}>
                  <IoChatbubbles css={{ fontSize: "3rem" }} />
                  <h2>Sales</h2>
                  <p>
                    Want to know how Droppah can help your business? Make a time
                    for us to call you or send our sales team a message.
                  </p>
                </Box>
                <Box stackGap={30}>
                  <Button
                    className="secondary -lg -center"
                    onClick={handleModal}
                    atag
                  >
                    Schedule a Sales Call
                  </Button>
                  <Link
                    className="-center link-floating"
                    to="/contact#message"
                    css={{ fontSize: "1rem!important" }}
                  >
                    Send us a message
                  </Link>
                </Box>
              </SupportCard>
              <SupportCard stackGap={40} size={50}>
                <Box stackGap={10}>
                  <IoHelpBuoy css={{ fontSize: "3rem" }} />
                  <h2>Support</h2>
                  <p>
                    Need some help with Droppah? Visit the Support Centre or get
                    in touch with our friendly support team.
                  </p>
                </Box>
                <Box stackGap={30}>
                  <Button
                    className="primary -lg -center"
                    to="https://support.droppah.com/hc/en-us"
                    atag
                    externalLink
                  >
                    Visit Support Centre{" "}
                    <IoOpenOutline css={{ top: "2px", marginLeft: "3px" }} />
                  </Button>
                  <a
                    className="-center link-floating"
                    href="mailto:support@droppah.com"
                    css={{ fontSize: "1rem!important" }}
                  >
                    support@droppah.com
                  </a>
                </Box>
              </SupportCard>
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <div className="site-anchor" id="message" />
      <Container>
        <Wrapper stackGap={80}>
          <Row stackGap={60}>
            <Box size={60} stackGap={50}>
              <div>
                <h3>Send our sales team a message</h3>
                <p>
                  How can we help you today? You can also reach us by emailing{" "}
                  <a href="mailto:sales@droppah.com">sales@droppah.com</a>.
                </p>
              </div>
              <div>
                <WebsiteContactForm />
              </div>
            </Box>
            <Box size={40} stackGap={45}>
              <Box stackGap={25}>
                <div>
                  <h3>Product Support</h3>
                  <p>
                    Having trouble getting set up or have a curly question you
                    can’t find the answer to? Our Support Centre has in depth
                    articles about Droppah.
                  </p>
                </div>
                <Box stackGap={20}>
                  <a
                    className="link-floating"
                    href="https://support.droppah.com/hc/en-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Support Centre{" "}
                    <IoOpenOutline css={{ top: "2px", marginLeft: "3px" }} />
                  </a>
                  <a
                    className="link-floating"
                    href="mailto:support@droppah.com"
                  >
                    support@droppah.com
                  </a>
                </Box>
              </Box>
              <Box>
                <h3>Our Office</h3>
                <p>We’re based in the best little capital in the world.</p>
                <p>
                  <b>11 Chews Lane, Level 1</b>
                </p>
                <p>
                  <b>Willis Street</b>
                </p>
                <p>
                  <b>PO Box 10067</b>
                </p>
                <p>
                  <b>Wellington 6143</b>
                </p>
                <p>
                  <b>New Zealand</b>
                </p>
              </Box>
              <Box stackGap={20}>
                <div>
                  <h3>Socials</h3>
                  <p>Stay up to date with Droppah.</p>
                </div>
                <Row stackGap={15} justify="flex-start" noBreak>
                  <a
                    href="https://www.linkedin.com/company/droppah"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{ fontSize: "2rem" }}
                    aria-label="Follow us on LinkedIn"
                  >
                    <IoLogoLinkedin />
                  </a>
                  <a
                    href="https://www.instagram.com/droppahhq/"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{ fontSize: "2rem" }}
                    aria-label="Follow us on Instgram"
                  >
                    <IoLogoInstagram />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCn1x-8Kycq_5My4v8yVVW5g "
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{ fontSize: "2rem" }}
                    aria-label="Subscribe to us on YouTube"
                  >
                    <IoLogoYoutube />
                  </a>
                </Row>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
      <Modal
        isActive={itemModalOpen}
        embedURL={`https://calendly.com/droppah-sales/sales-call`}
        embedTitle="Book a Call | Droppah"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Contact;
